import Button from "../components/Button";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ScrollToTopButton from "../components/ScrollTop";
import WpButton from "../components/WpButton";
import AppContextProvider from "../context/AppContext";

const Layout = ({ children }) => {
  return (
    <AppContextProvider>
      <div className="w-full">
        <Navbar />
        <main className="w-full flex flex-col items-center justify-center   h-full">
          {children}
        </main>
        <Button />
        <ScrollToTopButton />
        <WpButton />
        <Footer />
      </div>
    </AppContextProvider>
  );
};
export default Layout;
