export const ServiceDetail = [
  {
    title: "Faliyet Alanlarımız",
    button: "Devamını Gör",
    containerCard: [
      {
        title: "Kupa (Hacamat) Terapi",
        desc: "Hacamat tedavisi genellikle kuru ve yaş olarak uygulanmaktadır. Her iki uygulamada da kupalar negatif basınç oluşturarak cilde yerleştirilmekte, yaş kupa tedavisinde farklı olarak cilde çizikler atılarak vakumlanmış bölgedeki sıvı dışarı alınmaktadır.",
      },
    ],
    containerCard2: [
      {
        title: "Ozon Terapi",
        desc: "Ozon, Oksijen ( O2) molekülünün üç atomlu (O3) halidir. Havada bulunan oksijenden çeşitli doğa olayları sonucu oluşabildiği gibi tıbbi kullanım için ise özel cihazlar yardımıyla üretilir.Hastalıklara ve hastanın durumuna göre farklı uygulama yöntemleri mevcuttur.",
      },
    ],
    containerCard3: [
      {
        title: "Botoks",
        desc: "Botoks (Botulinum toksini), Clostridium botulinum adlı bakteriden elde edilen bir toksindir. Sinir uçlarında iletimi sağlayan maddelerin salınımını engelleyip, sinirler ile sinirlerin ulaştığı organlar arasındaki iletimi durdurarak etkisini gösterir. Sinir iletiminin durması, sinirin ulaştığı organın işlevlerinin azalmasını ya da tamamen kaybolmasını sağlar. Estetik ve kozmetik amaçlarla, mimik kaslarının hareketleri ile ortaya çıkan yüzdeki kırışıklıkları azaltmak, oluşumunu engellemek ve aşırı terleyen bölgelerdeki terlemeyi azaltmak amacıyla ve kronik migren tedavisinde kullanılabilmektedir.",
      },
    ],
    containerCard4: [
      {
        title: "Mezoterapi",
        desc: "Mezoterapi, 1952 yılında Dr. Michel Pistor tarafından kullanılan estetik amaçlı bir operasyondur. Günümüzde selülit, çatlak, leke, yara izi, saç tedavisi, cilt yenileme, anti-aging, spor yaralanmaları ve çeşitli deri hastalıkları başta olmak üzere pek çok amaçla kullanılır. Mezoterapinin temeli, cilt altında bulunan kolajen ve elastin gibi yapıları uyararak yenilemektir. Bu şekilde bölgedeki kan dolaşımının artırılması, yağ yakımının hızlanması ve lenf dolaşımının düzenlenmesi sağlanır.",
      },
    ],
    containerCard5: [
      {
        title: "Kronik yorgunluk",
        desc: "Kronik yorgunluk; kişilerin yeterli miktarda dinlenmesine rağmen sürekli olarak kendilerini yorgun hissetmeleri, iş verimliliklerinin düşmesi, isteksizlik ve sosyal hayattan uzaklaşma, mutsuzluk gibi belirtilerle kendini gösteren psikolojik bir sorundur.",
      },
    ],
    containerCard6: [
      {
        title: "PRP/CGF",
        desc: "Hastadan alınan az miktardaki kanın santifrujdan geçirilerek bileşenlerine ayrılması ve bu işlem sonucunda elde edilen platelet yönünden zengin plazmanın, yani yoğun miktarda trombosit ve büyüme faktörleri içeren kısmının, aynı kişiye doku yenilenmesini desteklemek ve cilt gençleştirme amacıyla enjekte edilmesi işlemidir.",
      },
    ],
    containerCard7: [
      {
        title: "Anti Aging",
        desc: "Anti agingde hedef cildin yenilenmesi, hormonların dengelenmesi ve yaşlanmaya bağlı rahatsızlıkların en aza indirilmesidir. Cilt yaşlanmasının pek çok nedeni vardır. Bu nedenleri azaltma için anti aging özellikli ürünler tercih ediliyor. Serbest radikaller, güneşin zararlı ışınlarına maruz kalma, azalan hormon, stres, sağlıksız beslenme, sigara kullanımı, durağan yaşam tarzı hızlı yaşlanmanın temel nedenleri arasında sayılabilir.",
      },
    ],
    containerCard8: [
      {
        title: "Cinsel Terapi",
        desc: "Cinsel terapi karşılıklı diyalog, paylaşım ve uzlaşı üzerine kurulu bir konuşma terapisi uygulamasıdır. Terapide yer alan kişiler tıbbi, psikolojik ya da kişisel problemlerinden hangilerinin cinsel yaşam içinde bir problem haline geldiğinin tespitine yönelik olarak iletişim kurarlar.",
      },
    ],
  },
];

export const ServiceDetailEn = [
  {
    title: "Our Areas of Activity",
    button: "See More",
    containerCard: [
      {
        title: "Cupping (Hijama) Therapy",
        desc: "Cupping therapy is typically applied in dry and wet forms. In both applications, cups are placed on the skin, creating negative pressure, while in wet cupping, incisions are made on the skin, and fluids from the vacuumed area are removed.",
      },
    ],
    containerCard2: [
      {
        title: "Ozone Therapy",
        desc: "Ozone is a three-atom form (O3) of the Oxygen (O2) molecule. It can be produced from atmospheric oxygen due to various natural processes and is produced for medical use using special devices. Different application methods are available depending on the disease and the patient's condition.",
      },
    ],
    containerCard3: [
      {
        title: "Botox",
        desc: "Botox (Botulinum toxin) is a toxin derived from the bacterium Clostridium botulinum. It works by blocking the release of substances that transmit nerve signals, interrupting the transmission between nerves and the organs they reach. Blocking nerve transmission reduces or eliminates the functions of the organ reached by the nerve. It can be used for aesthetic and cosmetic purposes to reduce wrinkles on the face caused by facial muscle movements, prevent their formation, and reduce excessive sweating in certain areas. It is also used in the treatment of chronic migraines.",
      },
    ],
    containerCard4: [
      {
        title: "Mesotherapy",
        desc: "Mesotherapy is an aesthetic procedure first used in 1952 by Dr. Michel Pistor. Today, it is used for various purposes, including the treatment of cellulite, stretch marks, blemishes, scars, hair therapy, skin rejuvenation, anti-aging, sports injuries, and various skin conditions. The basis of mesotherapy is to stimulate structures like collagen and elastin beneath the skin. This process increases blood circulation in the area, accelerates fat burning, and regulates lymphatic circulation.",
      },
    ],
    containerCard5: [
      {
        title: "Chronic Fatigue",
        desc: "Chronic fatigue is a psychological problem characterized by individuals constantly feeling tired despite getting enough rest, reduced work productivity, lack of motivation, withdrawal from social life, and unhappiness.",
      },
    ],
    containerCard6: [
      {
        title: "PRP/CGF",
        desc: "PRP/CGF (Platelet-Rich Plasma/Concentrated Growth Factors) involves taking a small amount of blood from the patient, centrifuging it to separate its components, and then injecting the platelet-rich plasma, which contains a high concentration of platelets and growth factors, into the same individual to support tissue regeneration and promote skin rejuvenation.",
      },
    ],
    containerCard7: [
      {
        title: "Anti-Aging",
        desc: "Anti-aging aims to rejuvenate the skin, balance hormones, and minimize age-related issues. There are numerous reasons for skin aging, including exposure to free radicals, harmful UV rays, decreasing hormones, stress, unhealthy diet, smoking, and a sedentary lifestyle.",
      },
    ],
    containerCard8: [
      {
        title: "Sexual Therapy",
        desc: "Sexual therapy is a talking therapy based on mutual dialogue, sharing, and compromise. Individuals in therapy communicate to determine which medical, psychological, or personal issues have become a problem in their sexual life.",
      },
    ],
  },
];
