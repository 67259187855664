export const ContactDetail = [
  {
    title: " İletişim Bilgileri",
    name: "Muayenehane İletişim Bilgilerimiz",
    name2: "Çalışma Saatlerimiz",
    name3: "Size Nasıl Yardımcı Olabiliriz?",
    desc: "Tedavilerimizden birine ihtiyacınız mı var? Aşağıdaki numaralar aracılığıyla bizimle iletişime geçin.",
    button: "Gönder",
    containerCard: [
      {
        title: "Pzt",
        title1: "Salı",
        title2: "Çar",
        title3: "Per",
        title4: "Cum",
        title5: "Cmt",
        title6: "Paz:Kapalı",
      },
    ],
  },
];
export const ContactDetailEn = [
  {
    title: "Contact Information",
    name: "Contact Information for Our Clinic",
    name2: "Our Working Hours",
    name3: "How Can We Assist You?",
    desc: "Do you need one of our treatments? Get in touch with us through the numbers below.",
    button: "Send",
    containerCard: [
      {
        title: "Mon",
        title1: "Tue",
        title2: "Wed",
        title3: "Thu",
        title4: "Fri",
        title5: "Sat",
        title6: "Sun:Closed",
      },
    ],
  },
];
