import Layout from "./Layout/Layout";
import AppRouter from "./router/AppRouter";

function App() {
  return (
    <Layout>
      <AppRouter />
    </Layout>
  );
}

export default App;
