export const LayoutData = [
  {
    lang: "Dil",
    title: "Anasayfa",
    title2: "Hakkımızda",
    title3: "Faliyet Alanlarımız",
    title4: "İletişim",
    title5: "Galeri",
    title6: "Bize Arayın",
    desc: "Dr. Ayşe Tokdemir ile doğal güzelliğinizi ön plana çıkarın. Botoks ve anti-aging uygulamaları konusundaki uzmanlığı ile Dr. Tokdemir, yaşlanma sürecini yavaşlatmanızı ve daha genç bir görünüm elde etmenizi sağlar. Güzellik ve özgüveni buluşturan bir uzmanla tanışın.",
    conntact: "E-posta ",
    contactdetail: "Gizlilik Politikası",
    button: "Gönder",
    buttonfix: "Hızlı Randevu Al",
  },
];
export const LayoutDataEn = [
  {
    lang: "Language",
    title: "Home",
    title2: "About Us",
    title3: "Our Services",
    title4: "Contact",
    title5: "Gallery",
    title6: "Call Us",
    desc: "Highlight your natural beauty with Dr. Ayşe Tokdemir. With expertise in Botox and anti-aging treatments, Dr. Tokdemir can help you slow down the aging process and achieve a more youthful appearance. Meet an expert who combines beauty and confidence.",
    conntact: "Contact me for mail",
    contactdetail: "Privacy Policy",
    button: "Send",
    buttonfix: "Book an Appointment",
  },
];
