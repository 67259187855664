import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

const WpButton = () => {
  return (
    <div className=" fixed bottom-20 left-0 w-full">
      <FloatingWhatsApp
        avatar="https://res.cloudinary.com/duotxzytv/image/upload/v1699256757/Logod%C3%BCz_1_yxuezi.png"
        chatMessage="Merhaba 👋 size nasıl yardımcı olabiliriz?"
        phoneNumber="05075611461"
        accountName="Dr. Ayşe Tokdemir"
        buttonClassName=" fixed bottom-40 left-10 w-full"
        chatboxClassName=" fixed bottom-40 left-10 w-full"
        statusMessage="çevrimiçi"
        allowEsc
        allowClickAway
        notification
        notificationSound
      />
    </div>
  );
};

export default WpButton;
