import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { GiRotaryPhone } from "react-icons/gi";
import LogoDarkSvg from "./LogoDarkSvg";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { AppContext } from "../context/AppContext";
import { useContext } from "react";
import TR from "./Svg/TR";
import En from "./Svg/En";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const [show, setShow] = useState(false);
  const { setLanguage, language } = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const clickHandle = async (lang) => {
    await i18n.changeLanguage(lang);
    if (lang === "en") {
      setLanguage(true);
    } else {
      setLanguage(false);
    }
  };
  const handleNav = () => {
    setNav(!nav);
  };
  const handleClick = (slug) => {
    window.location.assign(`/${slug}`);
    // navigate(`/${slug}`);
  };
  return (
    <>
      {show && (
        <>
          <div className="fixed h-32  w-52 top-24 right-[15%] bg-white flex flex-col items-center justify-center gap-1 z-50 ">
            <div
              className="flex items-center justify-center gap-2 "
              onClick={() => clickHandle("tr")}
            >
              <TR />
              <h1 className="text-xl">Türkçe</h1>
            </div>
            <div
              className="flex items-center justify-center gap-2 "
              onClick={() => clickHandle("en")}
            >
              <En />
              <h1 className="text-xl">English</h1>
            </div>
          </div>
        </>
      )}
      <div className="flex justify-between items-center h-24 max-w-full w-full mx-auto px-4 text-white z-10   backdrop-blur-lg fixed inset-x-0 top-0 right-0 ">
        <div className="w-full flex justify-between items-center">
          <div className=" pl-4 w-2/7">
            <LogoDarkSvg />
          </div>

          <ul className="hidden md:flex sm:w-5/7 items-center">
            <li
              className="text-[#7c8583db] p-4 text-lg cursor-pointer  "
              onClick={() => handleClick("")}
            >
              {" "}
              <p> {t("navname")} </p>
            </li>
            <li
              className="text-[#7c8583db] p-4 text-lg cursor-pointer "
              onClick={() => handleClick("hakkımızda")}
            >
              {" "}
              <p> {t("navname2")}</p>
            </li>
            <li
              className="text-[#7c8583db] p-4 text-lg cursor-pointer"
              onClick={() => handleClick("hizmetlerimiz")}
            >
              {" "}
              <p> {t("navname3")} </p>
            </li>
            <li
              className="text-[#7c8583db] p-4 text-lg cursor-pointer"
              onClick={() => handleClick("iletişim")}
            >
              {" "}
              <p> {t("navname4")}</p>
            </li>
            <li
              className="text-[#7c8583db] py-4 text-lg px-3"
              onClick={() => handleClick("galeri")}
            >
              {" "}
              <p> {t("navname5")} </p>
            </li>
            <li
              className="text-[#7c8583db] py-4 text-lg px-4 cursor-pointer"
              onClick={() => setShow(!show)}
            >
              {language === true ? <En /> : <TR />}
            </li>
            <li className=" px-2 flex items-center justify-center bg-[#b92b74] rounded-md opacity-60">
              <GiRotaryPhone size={25} color="white" />
              <a href="tel:03624311461">
                <h1 className="text-white p-4 text-md">
                  {" "}
                  <span className="font-bold"> {t("navname6")}: </span>0362 431
                  14 61{" "}
                </h1>
              </a>
            </li>
          </ul>
          <div onClick={handleNav} className="block md:hidden z-10">
            {nav ? (
              <AiOutlineClose size={20} color="black" />
            ) : (
              <AiOutlineMenu size={20} color="black" />
            )}
          </div>
        </div>

        <ul
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-screen border-r  bg-white ease-in-out duration-500 z-10"
              : "ease-in-out duration-500 fixed left-[-100%] z-10"
          }
        >
          <li className="  w-full p-4">
            <LogoDarkSvg />
          </li>
          <li
            className="text-[#7c8583db] p-4 text-lg cursor-pointer "
            onClick={() => handleClick("")}
          >
            {" "}
            <p>{t("navname")} </p>
          </li>
          <li
            className="text-[#7c8583db] p-4 text-lg cursor-pointer "
            onClick={() => handleClick("hakkımızda")}
          >
            {" "}
            <p>{t("navname2")} </p>
          </li>
          <li
            className="text-[#7c8583db] p-4 text-lg cursor-pointer"
            onClick={() => handleClick("hizmetlerimiz")}
          >
            {" "}
            <p>{t("navname3")} </p>
          </li>
          <li
            className="text-[#7c8583db] p-4 text-lg cursor-pointer"
            onClick={() => handleClick("iletişim")}
          >
            {" "}
            <p>{t("navname4")} </p>
          </li>
          <li
            className="text-[#7c8583db] p-4 text-lg cursor-pointer"
            onClick={() => handleClick("galeri")}
          >
            {" "}
            <p>{t("navname5")} </p>
          </li>
          <li className=" px-2 flex items-center justify-center bg-[#b92b74] rounded-md opacity-60 mt-5">
            <a href="tel:03624311461">
              <h1 className="text-white p-2 text-md">
                {" "}
                <span className="font-bold text-sm">{t("navname6")}: </span>0362
                431 14 61{" "}
              </h1>
            </a>
          </li>
          <li className="text-[#7c8583db] p-4 text-lg cursor-pointer  text-center">
            <h1 className="font-bold mb-4">{t("lang")}</h1>
            <div
              className="flex items-center  gap-2 "
              onClick={() => clickHandle("tr")}
            >
              <TR />
              <h1 className="text-md">Türkçe</h1>
            </div>
            <div
              className="flex items-center gap-2 "
              onClick={() => clickHandle("en")}
            >
              <En />
              <h1 className="text-md">English</h1>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
