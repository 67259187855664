import React from "react";
import { useTranslation } from "react-i18next";

function Button() {
  const { t, i18n } = useTranslation();
  const openUrl = () => {
    window.open(
      " https://www.doktortakvimi.com/ayse-tokdemir-dogan/anesteziyoloji-ve-reanimasyon-mezoterapi-ozon-terapi/samsun?fbclid=PAAaZnTFWOFAH9ubsL9-3LepWPzZV2rQ_Eo-Fwd49QkuoRzUQH6gDgm0tI7cU"
    );
  };

  return (
    <>
      <button
        onClick={openUrl}
        className="origin-bottom -rotate-90 fixed bottom-80 right-[-100px] w-[200px] h-16 bg-[#13426ca6] p-1 text-white rounded-t-md text-xl text-center cursor-pointer opacity-90 transition-opacity duration-300 hover:opacity-100"
      >
        {t("sidebutton")}
      </button>
    </>
  );
}

export default Button;
