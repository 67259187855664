import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { HomeDetail, HomeDetailEn } from "./data/Home";
import { AboutDetail, AboutDetailEn } from "./data/About";
import { ContactDetail, ContactDetailEn } from "./data/Contact";
import { ServiceDetail, ServiceDetailEn } from "./data/Service";
import { LayoutData, LayoutDataEn } from "./data/Layout";

const resources = {
  tr: {
    translation: {
      lang: LayoutData[0].lang,
      navname: LayoutData[0].title,
      navname2: LayoutData[0].title2,
      navname3: LayoutData[0].title3,
      navname4: LayoutData[0].title4,
      navname5: LayoutData[0].title5,
      navname6: LayoutData[0].title6,
      footerdesc: LayoutData[0].desc,
      footercontact: LayoutData[0].conntact,
      footercontactdetail: LayoutData[0].contactdetail,
      footerbutton: LayoutData[0].button,
      sidebutton: LayoutData[0].buttonfix,
      title: HomeDetail[0].name,
      desc: HomeDetail[0].desc,
      button: HomeDetail[0].button,
      context: HomeDetail[0].container[0].title,
      context2: HomeDetail[0].container[0].title1,
      context3: HomeDetail[0].container[0].title2,
      context4: HomeDetail[0].container[0].title3,
      context5: HomeDetail[0].container[0].title4,
      context6: HomeDetail[0].container[0].title5,
      context7: HomeDetail[0].container[0].title6,
      containertitle: HomeDetail[0].containertitle,
      containerdesc: HomeDetail[0].containerdesc,
      containerCard: HomeDetail[0].containerCard[0].title,
      containerCardDesc: HomeDetail[0].containerCard[0].desc,
      containerCardButton: HomeDetail[0].containerCard[0].button,
      containerCard2: HomeDetail[0].containerCard2[0].title,
      containerCardDesc2: HomeDetail[0].containerCard2[0].desc,
      containerCardButton2: HomeDetail[0].containerCard2[0].button,
      botox: HomeDetail[0].botox,
      botoxdesc: HomeDetail[0].botoxdesc,
      questions: HomeDetail[0].questions,
      questionsdesc: HomeDetail[0].questionsCard[0].question,
      questionsanswer: HomeDetail[0].questionsCard[0].answer,
      questionsdesc2: HomeDetail[0].questionsCard[1].question,
      questionsanswer2: HomeDetail[0].questionsCard[1].answer,
      questionsdesc3: HomeDetail[0].questionsCard[2].question,
      questionsanswer3: HomeDetail[0].questionsCard[2].answer,
      questionsdesc4: HomeDetail[0].questionsCard[3].question,
      questionsanswer4: HomeDetail[0].questionsCard[3].answer,
      questionsdesc5: HomeDetail[0].questionsCard[4].question,
      questionsanswer5: HomeDetail[0].questionsCard[4].answer,
      questionsdesc6: HomeDetail[0].questionsCard[5].question,
      questionsanswer6: HomeDetail[0].questionsCard[5].answer,
      experiance: HomeDetail[0].experiance,
      experiancetitle: HomeDetail[0].experiancetitle,
      experiancedesc: HomeDetail[0].experiancedesc,
      experiancebutton: HomeDetail[0].experiancebutton,
      aboutname: AboutDetail[0].name,
      abouttitle: AboutDetail[0].title,
      aboutdesc: AboutDetail[0].desc,
      contacttitle: ContactDetail[0].title,
      contactname: ContactDetail[0].name,
      contactname2: ContactDetail[0].name2,
      contactname3: ContactDetail[0].name3,
      contactdesc: ContactDetail[0].desc,
      contactbutton: ContactDetail[0].button,
      contact: ContactDetail[0].containerCard[0].title,
      contact1: ContactDetail[0].containerCard[0].title1,
      contact2: ContactDetail[0].containerCard[0].title2,
      contact3: ContactDetail[0].containerCard[0].title3,
      contact4: ContactDetail[0].containerCard[0].title4,
      contact5: ContactDetail[0].containerCard[0].title5,
      contact6: ContactDetail[0].containerCard[0].title6,
      sevirce: ServiceDetail[0].title,
      sevircebutton: ServiceDetail[0].button,
      servicecard: ServiceDetail[0].containerCard[0].title,
      servicecardDesc: ServiceDetail[0].containerCard[0].desc,
      servicecard1: ServiceDetail[0].containerCard2[0].title,
      servicecardDesc1: ServiceDetail[0].containerCard2[0].desc,
      servicecard2: ServiceDetail[0].containerCard3[0].title,
      servicecardDesc2: ServiceDetail[0].containerCard3[0].desc,
      servicecard3: ServiceDetail[0].containerCard4[0].title,
      servicecardDesc3: ServiceDetail[0].containerCard4[0].desc,
      servicecard4: ServiceDetail[0].containerCard5[0].title,
      servicecardDesc4: ServiceDetail[0].containerCard5[0].desc,
      servicecard5: ServiceDetail[0].containerCard6[0].title,
      servicecardDesc5: ServiceDetail[0].containerCard6[0].desc,
      servicecard6: ServiceDetail[0].containerCard7[0].title,
      servicecardDesc6: ServiceDetail[0].containerCard7[0].desc,
      servicecard7: ServiceDetail[0].containerCard8[0].title,
      servicecardDesc7: ServiceDetail[0].containerCard8[0].desc,
    },
  },
  en: {
    translation: {
      lang: LayoutDataEn[0].lang,
      navname: LayoutDataEn[0].title,
      navname2: LayoutDataEn[0].title2,
      navname3: LayoutDataEn[0].title3,
      navname4: LayoutDataEn[0].title4,
      navname5: LayoutDataEn[0].title5,
      navname6: LayoutDataEn[0].title6,
      footerdesc: LayoutDataEn[0].desc,
      footercontact: LayoutDataEn[0].conntact,
      footercontactdetail: LayoutDataEn[0].contactdetail,
      footerbutton: LayoutDataEn[0].button,
      sidebutton: LayoutDataEn[0].buttonfix,
      title: HomeDetailEn[0].name,
      desc: HomeDetailEn[0].desc,
      button: HomeDetailEn[0].button,
      context: HomeDetailEn[0].container[0].title,
      context2: HomeDetailEn[0].container[0].title1,
      context3: HomeDetailEn[0].container[0].title2,
      context4: HomeDetailEn[0].container[0].title3,
      context5: HomeDetailEn[0].container[0].title4,
      context6: HomeDetailEn[0].container[0].title5,
      context7: HomeDetailEn[0].container[0].title6,
      containertitle: HomeDetailEn[0].containertitle,
      containerdesc: HomeDetailEn[0].containerdesc,
      containerCard: HomeDetailEn[0].containerCard[0].title,
      containerCardDesc: HomeDetailEn[0].containerCard[0].desc,
      containerCardButton: HomeDetailEn[0].containerCard[0].button,
      containerCard2: HomeDetailEn[0].containerCard2[0].title,
      containerCardDesc2: HomeDetailEn[0].containerCard2[0].desc,
      containerCardButton2: HomeDetailEn[0].containerCard2[0].button,
      botox: HomeDetailEn[0].botox,
      botoxdesc: HomeDetailEn[0].botoxdesc,
      questions: HomeDetailEn[0].questions,
      questionsdesc: HomeDetailEn[0].questionsCard[0].question,
      questionsanswer: HomeDetailEn[0].questionsCard[0].answer,
      questionsdesc2: HomeDetailEn[0].questionsCard[1].question,
      questionsanswer2: HomeDetailEn[0].questionsCard[1].answer,
      questionsdesc3: HomeDetailEn[0].questionsCard[2].question,
      questionsanswer3: HomeDetailEn[0].questionsCard[2].answer,
      questionsdesc4: HomeDetailEn[0].questionsCard[3].question,
      questionsanswer4: HomeDetailEn[0].questionsCard[3].answer,
      questionsdesc5: HomeDetailEn[0].questionsCard[4].question,
      questionsanswer5: HomeDetailEn[0].questionsCard[4].answer,
      questionsdesc6: HomeDetailEn[0].questionsCard[5].question,
      questionsanswer6: HomeDetailEn[0].questionsCard[5].answer,
      experiance: HomeDetailEn[0].experiance,
      experiancetitle: HomeDetailEn[0].experiancetitle,
      experiancedesc: HomeDetailEn[0].experiancedesc,
      experiancebutton: HomeDetailEn[0].experiancebutton,
      aboutname: AboutDetailEn[0].name,
      abouttitle: AboutDetailEn[0].title,
      aboutdesc: AboutDetailEn[0].desc,
      contacttitle: ContactDetailEn[0].title,
      contactname: ContactDetailEn[0].name,
      contactname2: ContactDetailEn[0].name2,
      contactname3: ContactDetailEn[0].name3,
      contactdesc: ContactDetailEn[0].desc,
      contactbutton: ContactDetailEn[0].button,
      contact: ContactDetailEn[0].containerCard[0].title,
      contact1: ContactDetailEn[0].containerCard[0].title1,
      contact2: ContactDetailEn[0].containerCard[0].title2,
      contact3: ContactDetailEn[0].containerCard[0].title3,
      contact4: ContactDetailEn[0].containerCard[0].title4,
      contact5: ContactDetailEn[0].containerCard[0].title5,
      contact6: ContactDetailEn[0].containerCard[0].title6,
      sevirce: ServiceDetailEn[0].title,
      sevircebutton: ServiceDetailEn[0].button,
      servicecard: ServiceDetailEn[0].containerCard[0].title,
      servicecardDesc: ServiceDetailEn[0].containerCard[0].desc,
      servicecard1: ServiceDetailEn[0].containerCard2[0].title,
      servicecardDesc1: ServiceDetailEn[0].containerCard2[0].desc,
      servicecard2: ServiceDetailEn[0].containerCard3[0].title,
      servicecardDesc2: ServiceDetailEn[0].containerCard3[0].desc,
      servicecard3: ServiceDetailEn[0].containerCard4[0].title,
      servicecardDesc3: ServiceDetailEn[0].containerCard4[0].desc,
      servicecard4: ServiceDetailEn[0].containerCard5[0].title,
      servicecardDesc4: ServiceDetailEn[0].containerCard5[0].desc,
      servicecard5: ServiceDetailEn[0].containerCard6[0].title,
      servicecardDesc5: ServiceDetailEn[0].containerCard6[0].desc,
      servicecard6: ServiceDetailEn[0].containerCard7[0].title,
      servicecardDesc6: ServiceDetailEn[0].containerCard7[0].desc,
      servicecard7: ServiceDetailEn[0].containerCard8[0].title,
      servicecardDesc7: ServiceDetailEn[0].containerCard8[0].desc,
    },
  },
};

i18n.use(initReactI18next).init({
  lng: "tr",
  resources,
});

const language = i18n.language || "tr";
const homeDetail = language === "en" ? HomeDetailEn : HomeDetail;

export { homeDetail };
