import React, { useRef, useState } from "react";
import {
  BiLogoFacebookCircle,
  BiLogoInstagram,
  BiCalendarEvent,
} from "react-icons/bi";
import emailjs from "@emailjs/browser";
import LogoDarkSvg from "./LogoDarkSvg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const form = useRef(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_d8rgsnv",
          "template_yd2zbki",
          form.current,
          "4jSAQX16E0yYzQUeZ"
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }

    setName("");
    setEmail("");
    setMessage("");
  };
  return (
    <div className="max-w-[1900px] w-full mx-auto  pt-12   justify-between border-t-4 border-[#b9b7b7a1]  bg-[#7a7a7abe]">
      <div className="w-full  px-4  grid lg:grid-cols-2 gap-8 pb-5 ">
        <div className="w-[70%] pl-8 ">
          <div className=" w-full">
            <LogoDarkSvg />
          </div>
          <p className="my-3 ">{t("footerdesc")}</p>
          <div className="flex justify-start gap-6 md:w-[50%] my-6">
            <a href="https://m.facebook.com/profile.php?id=100081160817107&v=info&ref=ig_fb_profile_msite&funlid=1Jv1QOJjxNPzrb8S">
              <BiLogoFacebookCircle size={30} color="#b92b74" />
            </a>
            <a href="https://www.instagram.com/dr.aysetokdemir">
              {" "}
              <BiLogoInstagram size={30} color="#b92b74" />
            </a>
            <a href="https://www.doktortakvimi.com/ayse-tokdemir-dogan/anesteziyoloji-ve-reanimasyon-mezoterapi-ozon-terapi/samsun?fbclid=PAAaZnTFWOFAH9ubsL9-3LepWPzZV2rQ_Eo-Fwd49QkuoRzUQH6gDgm0tI7cU">
              <BiCalendarEvent size={30} color="#b92b74" />
            </a>
          </div>
        </div>
        <>
          <div className="lg:col-span-1 w-full   hidden  md:flex flex-col   ">
            <div className="flex flex-col sm:flex-row items-center gap-4 w-full ">
              <form ref={form} onSubmit={handleSubmit}>
                <div className="flex items-center justify-center gap-4 ">
                  <input
                    type="text"
                    name="to_name"
                    placeholder="Name"
                    className="p-3 flex w-60 rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    className="p-3 flex w-60 rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                    type="email"
                    name="from_name"
                    placeholder="E-mail"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <textarea
                  name="message"
                  className="p-3 mt-4 flex w-full rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Mesaj"
                />
                <input
                  type="submit"
                  value={t("footerbutton")}
                  className="bg-[#13426ca6] hover:bg-white hover:text-[#13426ca6]  text-white rounded-md font-medium w-[200px] cursor-pointer  my-6 px-6 py-3  "
                />
              </form>
            </div>
            <p>
              {t("footercontact")}
              <span className="text-[#b92b74] px-2 cursor-pointer  font-bold">
                {t("footercontactdetail")}
              </span>
            </p>
          </div>
          {/* mobil */}
          <div className="w-full block md:hidden">
            <div className="lg:col-span-1 flex flex-col w-full  ">
              <div className="flex flex-col sm:flex-row items-center gap-4 w-full ">
                <form ref={form} onSubmit={handleSubmit}>
                  <div className="flex items-center justify-center gap-4 ">
                    <input
                      type="text"
                      name="to_name"
                      placeholder="Name"
                      className="p-3 flex w-40 rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <input
                      className="p-3 flex w-40 rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                      type="email"
                      name="from_name"
                      placeholder="E-mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <textarea
                    name="message"
                    className="p-3 mt-4 flex w-full rounded-md  font-bold border-2 border-[#dadada63] indent-2  outline-none focus:border-[#3567b980] focus:ring-1 rounded-tl rounded-bl "
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Mesaj"
                  />
                  <input
                    type="submit"
                    value={t("footerbutton")}
                    className="bg-[#13426ca6] hover:bg-white hover:text-[#13426ca6]  text-white rounded-md font-medium w-[200px] cursor-pointer  my-6 px-6 py-3  "
                  />
                </form>
              </div>
              <p>
                {t("footercontact")}
                <span className="text-[#b92b74] px-2 cursor-pointer  font-bold">
                  {t("footercontactdetail")}
                </span>
              </p>
            </div>
          </div>
        </>
      </div>
      <div className="w-full flex items-center justify-center h-12   text-white border-t-2 border-[#7c8583db]  bg-black">
        <h1>© 2023 Dr. Ayşe Tokdemir Doğan</h1>
      </div>
    </div>
  );
};

export default Footer;
