import React, { createContext, useEffect, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [language, setLanguage] = useState([]);

  useEffect(() => {}, [language]);

  return (
    <AppContext.Provider
      value={{
        setLanguage,
        language,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
