export const HomeDetail = [
  {
    name: "Dr. Ayşe Tokdemir ile Zamana Meydan Okuyun",
    desc: "Dr. Ayşe Tokdemir ile doğal güzelliğinizi ön plana çıkarın. Güzellik ve anti-aging vb. uygulamaları konusundaki uzmanlığı ile Dr. Tokdemir, yaşlanma sürecini yavaşlatmanızı ve daha genç bir görünüm elde etmenizi sağlar. Güzellik ve özgüveni buluşturan bir uzmanla tanışın.",
    button: "Devamını Gör",
    containertitle: "Tedavi Yöntemlerimiz",
    containerdesc:
      "Ücretsiz Bilgi Edinin Muayenehanemizde kişilere “Hastalık yoktur, hasta vardır” mantığı ile sadece fiziksel şikayeti giderme doğrultusunda değil psikolojik arkaplanı ile birlikte değerlendirerek ve uygun tamamlayıcı tıp yöntemleri uygulayarak kalıcı tedavi etmeyi amaçlamaktayız.",
    containerCard: [
      {
        title: "Hacamat",
        desc: "Hacamat; Sırt ağrısı, Miyozit, Tendinopati ve spor yaralanmalarında, iskiyalji, lumbalji, brakiyalji, disk patolojileri, astım, bronşit, sinüzit tedavilerinde uygulanır.",
        button: "Devamı için tıklayın",
      },
    ],
    containerCard2: [
      {
        title: "Ozon Terapi",
        desc: "Ozon, Oksijen( O2) molekülünün üç atomlu (O3) halidir. Havada bulunan oksijenden çeşitli doğa olayları sonucu oluşabildiği gibi tıbbi kullanım için ise özel cihazlar yardımıyla üretilir.",
        button: "Devamı için tıklayın",
      },
    ],

    botox: "BOTOKS NEDİR ?",
    botoxdesc:
      "Botoks (Botulinum toksini), Clostridium botulinum adlı bakteriden elde edilen bir toksindir. Sinir uçlarında iletimi sağlayan maddelerin salınımını engelleyip, sinirler ile sinirlerin ulaştığı organlar arasındaki iletimi durdurarak etkisini gösterir. Sinir iletiminin durması, sinirin ulaştığı organın işlevlerinin azalmasını ya da tamamen kaybolmasını sağlar. Estetik ve kozmetik amaçlarla, mimik kaslarının hareketleri ile ortaya çıkan yüzdeki kırışıklıkları azaltmak, oluşumunu engellemek ve aşırı terleyen bölgelerdeki terlemeyi azaltmak amacıyla ve kronik migren tedavisinde kullanılabilmektedir.",
    questions: "Sık Sorulan Sorular",
    questionsCard: [
      {
        question: "Hacamat tedavisi esnasında ağrı hisseder miyiz?",
        answer:
          "Hacamat tedavisi esnasında çok yüzeysel çizikler olduğu için ince bir sızı hissedilebilir. Bazı insanlar onu da hissetmez.",
      },
      {
        question: "Hacamat tedavisinin yan etkileri var mıdır?",
        answer: "Hacamat tedavisinin hiçbir yan etkisi yoktur.",
      },
      {
        question: " Hacamat tedavisinin herhangi bir yaş sınırı var mıdır?",
        answer:
          "Tıbbi Hacamat tedavisi doktor kontrolünde yapılırsa iki yaşından büyük herkese uygun dozlarda yapılabilir.",
      },
      {
        question: " Hacamat tedavisinin belirli günleri var mıdır?",
        answer:
          "Hacamat tedavisi hastalık anında her gün ve her saat yapılabilir. Gün konusunda dinimizde yasak men etme yoktur. Resulullah kendisine 47 kez hacamat yaptırmış hadis kaynaklarında hiç gün sormamıştır.",
      },
      {
        question: " Hacamat tedavisinin herhangi bir yaş sınırı var mıdır?",
        answer:
          "Tıbbi Hacamat tedavisi doktor kontrolünde yapılırsa iki yaşından büyük herkese uygun dozlarda yapılabilir.",
      },
      {
        question: " Hacamat tedavisinin belirli günleri var mıdır?",
        answer:
          "Hacamat tedavisi hastalık anında her gün ve her saat yapılabilir. Gün konusunda dinimizde yasak men etme yoktur. Resulullah kendisine 47 kez hacamat yaptırmış hadis kaynaklarında hiç gün sormamıştır.",
      },
    ],
    experiance: "Ozon Terapi",
    experiancetitle: "Ozon Terapi kullanım alanları nelerdir?",
    experiancedesc:
      "Ozon, Oksijen ( O2) molekülünün üç atomlu (O3) halidir. Havada bulunan oksijenden çeşitli doğa olayları sonucu oluşabildiği gibi tıbbi kullanım için ise özel cihazlar yardımıyla üretilir.Hastalıklara ve hastanın durumuna göre farklı uygulama yöntemleri mevcuttur.",
    experiancebutton: " Ozon Terapisi İçin Randevu Alın",
    container: [
      {
        title: "Anti Aging",
        title1: "PRP/CGF",
        title2: "Ozon Tedavi",
        title3: "Mezoterapi",
        title4: "Hacamat",
        title5: "Kronik Yorgunluk",
        title6: "Güzellik",
      },
    ],
  },
];
export const HomeDetailEn = [
  {
    name: "Defy Time with Dr. Ayşe Tokdemir",
    desc: "Enhance your natural beauty with Dr. Ayşe Tokdemir. With expertise in Botox and anti-aging treatments, Dr. Tokdemir can help slow down the aging process and achieve a more youthful appearance. Meet an expert who combines beauty and confidence.",
    button: "Learn More",
    containertitle: "Our Treatment Methods",
    containerdesc:
      "Get Free Information We aim to provide permanent treatment by evaluating not only physical complaints but also the psychological background, applying suitable complementary medical methods, with the logic of 'There is no disease, there is a patient' for individuals in our clinic.",
    containerCard: [
      {
        title: "Hijama (Cupping Therapy)",
        desc: "Hijama is used for the treatment of back pain, myositis, tendinopathy, sports injuries, sciatica, lumbago, brachialgia, disc pathologies, asthma, bronchitis, and sinusitis.",
        button: "Read More",
      },
    ],
    containerCard2: [
      {
        title: "Ozone Therapy",
        desc: "Ozone is a three-atom form (O3) of oxygen (O2). It can be naturally produced from the oxygen in the air through various natural processes, or it can be generated for medical use with special devices.",
        button: "Read More",
      },
    ],

    botox: "WHAT IS BOTOX?",
    botoxdesc:
      "Botox (Botulinum toxin) is a toxin derived from the bacterium Clostridium botulinum. It works by blocking the release of neurotransmitters at nerve endings, stopping the transmission between nerves and the organs they reach. This cessation of nerve transmission reduces or completely eliminates the functions of the organ the nerve reaches. Botox can be used for aesthetic and cosmetic purposes to reduce and prevent wrinkles caused by the movement of facial muscles and to reduce excessive sweating in certain areas. It can also be used for the treatment of chronic migraines.",
    questions: "Frequently Asked Questions",
    questionsCard: [
      {
        question: "Do we feel pain during hijama therapy?",
        answer:
          "During hijama therapy, there may be a slight sensation of pain as it involves very superficial incisions. Some people may not feel it at all.",
      },
      {
        question: "Are there any side effects of hijama therapy?",
        answer: "There are no side effects of hijama therapy.",
      },
      {
        question: "Is there an age limit for hijama therapy?",
        answer:
          "If medical hijama therapy is performed under the supervision of a doctor, it can be done for individuals over the age of two in appropriate doses.",
      },
      {
        question: "Are there specific days for hijama therapy?",
        answer:
          "Hijama therapy can be performed at any time and on any day, especially when a person is ill. There are no restrictions on the day of the week for hijama therapy in our religion. In hadith sources, it is reported that the Prophet (peace be upon him) had hijama performed on him 47 times, and he did not inquire about the specific day.",
      },
      {
        question: "Is there an age limit for hijama therapy?",
        answer:
          "If medical hijama therapy is performed under the supervision of a doctor, it can be done for individuals over the age of two in appropriate doses.",
      },
      {
        question: "Are there specific days for hijama therapy?",
        answer:
          "Hijama therapy can be performed at any time and on any day, especially when a person is ill. There are no restrictions on the day of the week for hijama therapy in our religion. In hadith sources, it is reported that the Prophet (peace be upon him) had hijama performed on him 47 times, and he did not inquire about the specific day.",
      },
    ],
    experiance: "Ozone Therapy",
    experiancetitle: "Where can ozone therapy be used?",
    experiancedesc:
      "Ozone is a three-atom form (O3) of oxygen (O2). It can be naturally produced from the oxygen in the air through various natural processes, or it can be generated for medical use with special devices. There are different application methods available depending on the disease and the condition of the patient.",
    experiancebutton: "Schedule an Ozone Therapy Appointment",
    container: [
      {
        title: "Anti-Aging",
        title1: "PRP/CGF",
        title2: "Ozone Therapy",
        title3: "Mesotherapy",
        title4: "Cupping (Hijama)",
        title5: "Chronic Fatigue",
        title6: "Beauty",
      },
    ],
  },
];
