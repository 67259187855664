import { BrowserRouter, Routes, Route } from "react-router-dom";
import React, { lazy } from "react";

const HomePage = lazy(() => import("../Views/Home"));
const HakkımızdaPage = lazy(() => import("../Views/Hakkımızda"));
const GaleryPage = lazy(() => import("../Views/Galery"));
const ContactPage = lazy(() => import("../Views/Contact"));
const ServicesPage = lazy(() => import("../Views/Services"));
const DetailPage = lazy(() => import("../Views/Detail"));

const pages = [
  { path: "/", element: <HomePage /> },
  { path: "/galeri", element: <GaleryPage /> },
  { path: "/hakkımızda", element: <HakkımızdaPage /> },
  { path: "/iletişim", element: <ContactPage /> },
  { path: "/hizmetlerimiz", element: <ServicesPage /> },
  { path: "/detail/:slug", element: <DetailPage /> },
  // { path: "*", element: <NotFound /> },
];

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {pages?.map((page, index) => {
          return <Route key={index} path={page.path} element={page.element} />;
        })}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
