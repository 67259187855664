import React from "react";

const En = () => {
  return (
    <svg
      width="64px"
      height="34px"
      viewBox="0 -4 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g id="SVGRepo_iconCarrier">
        {" "}
        <g clipPath="url(#clip0_503_2952)">
          {" "}
          <rect width={28} height={20} rx={2} fill="white" />{" "}
          <mask
            id="mask0_503_2952"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={28}
            height={20}
          >
            {" "}
            <rect width={28} height={20} rx={2} fill="white" />{" "}
          </mask>{" "}
          <g mask="url(#mask0_503_2952)">
            {" "}
            <rect width={28} height={20} fill="#0A17A7" />{" "}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-1.28244 -1.91644L10.6667 6.14335V-1.33333H17.3334V6.14335L29.2825 -1.91644L30.7737 0.294324L21.3263 6.66667H28V13.3333H21.3263L30.7737 19.7057L29.2825 21.9165L17.3334 13.8567V21.3333H10.6667V13.8567L-1.28244 21.9165L-2.77362 19.7057L6.67377 13.3333H2.95639e-05V6.66667H6.67377L-2.77362 0.294324L-1.28244 -1.91644Z"
              fill="white"
            />{" "}
            <path
              d="M18.668 6.33219L31.3333 -2"
              stroke="#DB1F35"
              strokeWidth="0.666667"
              strokeLinecap="round"
            />{" "}
            <path
              d="M20.0128 13.6975L31.3666 21.3503"
              stroke="#DB1F35"
              strokeWidth="0.666667"
              strokeLinecap="round"
            />{" "}
            <path
              d="M8.00555 6.31046L-3.83746 -1.67099"
              stroke="#DB1F35"
              strokeWidth="0.666667"
              strokeLinecap="round"
            />{" "}
            <path
              d="M9.29006 13.6049L-3.83746 22.3105"
              stroke="#DB1F35"
              strokeWidth="0.666667"
              strokeLinecap="round"
            />{" "}
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 12H12V20H16V12H28V8H16V0H12V8H0V12Z"
              fill="#E6273E"
            />{" "}
          </g>{" "}
        </g>{" "}
        <defs>
          {" "}
          <clipPath id="clip0_503_2952">
            {" "}
            <rect width={28} height={20} rx={2} fill="white" />{" "}
          </clipPath>{" "}
        </defs>{" "}
      </g>
    </svg>
  );
};

export default En;
